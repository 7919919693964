import penaltyApi from "@/api/penalty";

export default {
    methods: {
        getReceipt(id) {
            penaltyApi.getReceipt(id).then(response => {
                const link = document.createElement('a');
                link.href = response.data.receipt_url;
                link.target = '_blank';
                // link.download = this.pdfFileName;

                // Simulate a click on the element <a>
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    }
}
